import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { checkSelectedBusinessRpaMode } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import ActivityEvents from "const/ActivityEvents";
import Button from "nlib/ui/Button";
import DateRangePickerExtended from "nlib/common/DateRangePickerExtended";
import React, { useCallback, useMemo } from "react";
import Row from "nlib/ui/Row";
import Select from "nlib/ui/Select";
import Utils from "utils/Utils";
import classNames from "classnames";
import useEnvVars from "hooks/useEnvVars";

const {
  TYPES: {
    RPA_STARTED,
    RPA_FINISHED,
    RPA_FAILED,
    GPT_AI_FINE_TUNE_STARTED,
    GPT_AI_FINE_TUNE_FINISHED,
    GPT_AI_FINE_TUNE_FAILED,
    TRANSACTIONS_CATEGORIZED,
    TRANSACTIONS_NOT_CATEGORIZED,
    TRANSACTIONS_AUTO_CATEGORIZED,
    TRANSACTIONS_AUTO_APPROVED,
    TRANSACTIONS_AUTO_ASK_CLIENT
  }
} = ActivityEvents;

const EVENT_TYPE_OPTIONS = Object.values(ActivityEvents.TYPES);

const RPA_EVENTS = [
  RPA_STARTED,
  RPA_FINISHED,
  RPA_FAILED,
  GPT_AI_FINE_TUNE_STARTED,
  GPT_AI_FINE_TUNE_FINISHED,
  GPT_AI_FINE_TUNE_FAILED,
  TRANSACTIONS_CATEGORIZED,
  TRANSACTIONS_NOT_CATEGORIZED,
  TRANSACTIONS_AUTO_CATEGORIZED,
  TRANSACTIONS_AUTO_APPROVED,
  TRANSACTIONS_AUTO_ASK_CLIENT
];

const Filters = ({ disabled }) => {
  const [envVars, setEnvVars] = useEnvVars();

  const { uiTexts } = useSelector(getTextsData);

  const rpaMode = useSelector(checkSelectedBusinessRpaMode);

  const {
    fromDate,
    toDate,
    type,
    month
  } = envVars;

  const fromToDate = useMemo(() => [fromDate, toDate], [fromDate, toDate]);

  const filtersActive = [fromDate, toDate, type].some(Boolean);

  const showResetButton = [filtersActive, month].some(Boolean);

  const typeOptions = useMemo(() => [
    type && { label: uiTexts.all },
    ...(
      (rpaMode ? EVENT_TYPE_OPTIONS : EVENT_TYPE_OPTIONS.filter((eventType) => !RPA_EVENTS.includes(eventType)))
        .map((eventType) => ({ value: eventType, label: uiTexts[eventType] }))
    )
  ], [rpaMode, type, uiTexts]);

  const handleDateChange = useCallback((value) => {
    const [from, to] = value;

    setEnvVars({ fromDate: from, toDate: to, month: null });
  }, [setEnvVars]);

  const handleTypeChange = useCallback((value) => {
    setEnvVars({ type: value, month: null });
  }, [setEnvVars]);

  const handleResetButtonClick = useCallback(() => {
    setEnvVars({ text: null, fromDate: null, toDate: null, accountId: null, type: null, month: null });
  }, [setEnvVars]);

  return (
    <div className={classNames(Css.filters, filtersActive && Css.active)} disabled={disabled}>
      <Row>
        <div className={Css.date}>
          <DateRangePickerExtended
            className={Css.datePicker}
            range={!Utils.checkIsTouchDevice()}
            active={!!fromDate && !!toDate}
            value={fromToDate}
            placeholder={uiTexts.selectDate}
            onChange={handleDateChange} />
        </div>

        <Select
          modal={Utils.checkIsTouchDevice()}
          active={!!type}
          className={Css.type}
          placeholder={uiTexts.selectType}
          iconBefore={Icons.Pulse}
          value={type}
          options={typeOptions}
          onChange={handleTypeChange} />

        <Button
          large outline danger
          className={classNames(Css.resetButton, showResetButton && Css.active)}
          icon={Icons.X}
          onClick={handleResetButtonClick}>
          {uiTexts.resetFilters}
        </Button>
      </Row>
    </div>
  );
};

export default React.memo(Filters);
